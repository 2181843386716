	.stcultura0 {
	    fill: #BCBCBC;
	}

	.stcultura1 {
	    fill: #A3A3A3;
	}

	.stcultura2 {
	    fill: #898989;
	}

	.stcultura3 {
	    fill: #70198E;
	}

	.stcultura4 {
	    fill: #2D2D2D;
	}

	.stcultura5 {
	    fill: #1C1C1C;
	}

	.stcultura6 {
	    fill: #3A3A3A;
	}

	.stcultura7 {
	    fill: #FFB743;
	}

	.stcultura8 {
	    fill: #0F0F0F;
	}

	.stcultura9 {
	    fill: #5C70FF;
	}

	.stcultura10 {
	    fill: #FFFFFF;
	}

	.stcultura11 {
	    fill: #F94141;
	}

	.stcultura12 {
	    fill: #212121;
	}

	.stcultura13 {
	    fill: #4C4C4C;
	}

	.stcultura14 {
	    fill: #F5C6B5;
	}

	.stcultura15 {
	    fill: #DF7E31;
	}

	.stcultura16 {
	    fill: #1E1E1E;
	}

	.stcultura17 {
	    fill: #EF8F3A;
	}

	.stcultura18 {
	    fill: #F2B09A;
	}

	.stcultura19 {
	    fill: #E4424B;
	}

	.stcultura20 {
	    fill: #7C58D0;
	}

	.stcultura21 {
	    fill: #9574D7;
	}

	.stcultura22 {
	    fill: #C4B7F8;
	}

	.stcultura23 {
	    fill: #A99DED;
	}

	.stcultura24 {
	    fill: #8A80DB;
	}

	.stcultura25 {
	    fill: #D06E2A;
	}

	.stcultura26 {
	    fill: #E30613;
	}

	.stcultura27 {
	    fill: #9DF771;
	}